<template>
<div class="in-report-manage">
    <component :is="views"></component>
</div>
</template>

<script>
import rsList from '@/views/SBLXGL/components/sblxList';
export default {
    data() {
        return {
            views: rsList
        }
    },
    methods: {},
    mounted() {}
}
</script>
