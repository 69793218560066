<template>
<div class="search_container searchArea">
    <el-form :inline="true" :model='searchInfo' ref="searchInfo" :rules="rules">
        <el-form-item label="设备类型名称">
            <el-input v-model="searchInfo.name" @change="nameChange" clearable></el-input>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="searchList()">查询</el-button>
            <el-button type="primary" @click="addDeviceType()">新增设备类型</el-button>
        </el-form-item>

    </el-form>
</div>
</template>

<script>

export default {
    name: 'searchItem',
    props: {
        searchInfo: {
            type: Object,
            required: true
        }

    },
    data() {
        return {
            rules: {}
        }
    },
    computed: {},
    created() {},
    methods: {
        // 查询
        searchList() {
            this.$emit('searchList', this.searchInfo)
        },
        addDeviceType(){
             this.$emit('addDeviceType')
        },
        nameChange(e) {
            this.searchInfo.name = e
            this.$emit('update:searchInfo', this.searchInfo)
        }
    }
}
</script>

<style>
.search_container {
    padding: 40px 0 18px;
    /* border-bottom: 1px solid #eff1f4; */
}
.el-date-editor .el-range-separator{
    padding:0;
}
</style>
